<template>
    <div class="autocompleter-transform-find-blocker">
        <v-autocomplete
            v-model="model"
            :items="items"
            :loading="loading"
            :search-input.sync="search"
            label="Select worker"
            item-text="workerFullname"
            item-value="workerId"
            hide-no-data
            :disabled="disabled"
            clearable
            :append-icon="'mdi-magnify'"
        >
            <template v-slot:item="data">
                <v-list-item-content style="margin: 0px; padding-left: 10px;">
                    <v-list-item-title v-html="data.item.workerFullname"></v-list-item-title>
                    <v-list-item-subtitle></v-list-item-subtitle>
                </v-list-item-content>
            </template>
        </v-autocomplete>
    </div>
</template>

<script>

export default ({
    name: 'WorkersAutocompleter',
    data: function(){
        return {
			model: null,
			disabled: false,
			loading: false,
			search: "",
            items: [],
        }
    },
    props: {
        workers: Object
    },
    mounted(){
    },
	watch: {
        workers: function(){
            if(this.workers){
                this.items=[]
                for(let worker in this.workers){
                    this.items.push({
                        workerId: worker,
                        workerFullname: this.workers[worker]
                    })
                }
            }
        },
        model: function() {

            var id = this.model;
            var item = null;
            for(let i = 0; i < this.items.length; i++){
                if(this.items[i].workerId == id){
                    item = this.items[i];
                }
            }
            this.$emit("input", item);
        },
        outerModel: function() {
            this.model = this.outerModel;
        },
        value: function(){
            this.model = this.value;
        }
    },
})
</script>

<style>
    .autocompleter-transform-find-blocker .v-select--is-menu-active .v-input__icon--append .v-icon {
        transform: none;
    }
</style>

<style scoped>

.autocompleter-transform-find-blocker{
    min-width: 50%;
}

.v-autocomplete{
    min-width: 100% !important;
}

</style>
